<template>
    <div class="report">
        <!-- 测评报告 -->
        <div class="regionTopImg" :style="{ backgroundImage: `url(${bannerLogo})` }">
            <!-- <img :src="bannerUrl" alt=""> -->
        </div>
        <div class="region">
            <!-- <div class="regionTopImg">
                <img src="../../assets/home/banner.jpg" alt="">
            </div> -->
            <div class="regionContent" v-if="tableData.length > 0">
                <div class="item" v-for="item in tableData" :key="item.id">
                    <div class="itemTop">
                        <p class="title" style="display:flex; justify-content: space-between;">
                            <span>{{ item.gaugeName }}</span> <span>{{ item.userName }}</span>
                        </p>
                        <p>{{ item.createTime }}</p>
                        <!-- <p>{{item.userName}}</p> -->
                    </div>
                    <div class="itemBottom">
                        <span @click="answerRecord(item)">答题记录</span>
                        <!-- <span  class="noLook">查看报告</span>
                        <span @click="answerRecord(item)">答题记录</span>
                        <span v-if="item.isReport === 0" class="noLook">查看报告</span> -->
                        <span v-if="item.isReport === 0" class="noLook">查看报告</span>
                        <span class="look" v-else @click="viewReport(item)">查看报告</span>
                    </div>
                </div>
            </div>
            <div v-else class="noReport">
                暂无测评报告
            </div>
            <div class="toBottom" v-if="tableData.length > 0">
                没有更多了...
            </div>
        </div>
    </div>
</template>

<script>
import { getReportList } from '@/api/report'
export default {
    data() {
        return {
            tableData: [],
            bannerLogo: null
        }
    },
    methods: {
        // 查看报告
        viewReport(val) {
            let token = 'Lyn' + localStorage.getItem('token')
            console.log(token)
            // 判断是否为微信内置浏览器，如果是则将token加在地址栏中
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                this.$router.push({
                    path: `/home/viewReport`,
                    query: {
                        id: val.id,
                        code: token
                    }
                })
                // this.$refs.openbrowser.open()
                // alert("请使用浏览器打开！");
                // location.href="https://cp.xbssc.vip"

            } else {
                this.$router.push({
                    path: `/home/viewReport`,
                    query: {
                        id: val.id
                    }
                })
            }
        },
        // 查看答题记录
        answerRecord(val) {
            this.$router.push({
                path: '/home/answerRecord',
                query: {
                    id: val.id
                }
            });
        },
        async getReportsList() {
            const res = await getReportList();
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data;
            }
        }
    },
    created() {
        this.getReportsList();
        this.bannerLogo = sessionStorage.getItem('banner')
    }
}
</script>

<style lang="scss" scoped>
.report {
    .regionTopImg {
        img {
            height: 100%;
        }
    }
    .region {
            .noReport {
                width: 100%;
                text-align: center;
                padding-top: 30px;
                font-size: 14px;
                color: var(--custom-color);
            }
        }

    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    .toBottom {

        padding-top: 25px;
        box-sizing: border-box;
        height: 60px;
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #7D7D7D;
    }

    .regionContent {
        background: white;
        padding-top: 20px;
        padding-bottom: 20px;

        .itemBottom {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 35px;
            width: 100%;
            background: var(--custom-color);
            border-radius: 4px;
            color: white;

            span {
                display: inline-block;
                width: 50%;
                box-sizing: border-box;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;

                // line-height: 100%;
            }

            .look {
                border-left: 1px solid #FFFFFF;
            }

            .noLook {
                border-radius: 0px 3px 3px 0px;
                height: 100%;
                background: #EEEEEE;
                // border-radius:0px 4px 4px 0px;
            }
        }


        .item {
            margin: auto;
            // box-shadow: 0px 2px 4px 0px rgba(148, 146, 146, 0.25);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
            height: 110px;
            // width: 650px;
            // margin-top: 20px;
            margin-bottom: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px 15px;
            box-sizing: border-box;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin: 0;
                padding: 0;
                font-size: 16px;
                font-weight: 400;
                color: #7D7D7D;
            }

            .title {
                font-size: 16px;
                font-weight: 400;
                color: #303030;
            }

            .itemBottom {
                width: 100%;
                display: flex;
                justify-content: space-between;

                // margin-top: 20px;
                button {
                    background: #028DA9;
                    border-radius: 4px;
                    border: none;
                    color: white;
                    width: 49%;
                    height: 35px;
                }
            }



        }
    }
}

@media screen and (min-width: 751PX) {
    // .report {
    //     // display: flex;
    //     // justify-content: center;
    // }

    .regionTopImg {
        height: 200px;
        width: 100%;
        // background-image: url('../../assets/home/banner.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .region {
        width: 900px;

        // height: calc(100% - 80px);


        .item {
            width: 650px;
        }
    }
}

@media screen and (max-width: 750PX) {
    .regionTopImg {
        height: 150px;
        width: 100%;
        // background-image: url('../../assets/home/banner.png');
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .region {
        width: 100%;



        .item {
            width: 80%;
        }


    }
}
</style>